import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { APIConstant } from '../constants/APIConstants';

@Injectable({
  providedIn: 'root',
})
export class AdminInvoiceReportService {
  constructor(private appService: AppService) {}

  getAll(params: {
    pageNumber: number;
    pageSize: number;
    sortField?: string;
    sortOrder?: string;
    search?: string;
    MinDate?: string;
    MaxDate?: string;
    RHId?: string;
    Status?: string;
  }) {
    return this.appService.get(
      `${APIConstant.adminInvoiceReport.getAll}`,
      params
    );
  }

  getOne(params: { id: string }) {
    return this.appService.get(
      `${APIConstant.adminInvoiceReport.getOne}`,
      params
    );
  }

  getCards(params: {
    search?: string;
    MinDate: string;
    MaxDate: string;
    RHId?: string;
    Status?: string;
  }) {
    return this.appService.get(
      `${APIConstant.adminInvoiceReport.getAnalytics}`,
      params
    );
  }
}

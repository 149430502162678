import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { APIConstant } from '../constants/APIConstants';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminAnalyticsService {
  constructor(private appService: AppService) {}

  getLgaCard(params: {
    search?: string;
    MinDate: string;
    MaxDate: string;
    ESId?: string;
    PCId?: string;
    TaxPayerCategory: string;
    TOId?: string;
    MDAId?: string;
    PGId?: string;
  }) {
    return this.appService.get(
      `${APIConstant.adminDashboard.getLgaAnalytics}`,
      params
    );
  }

  getPercentagePaidUnpaidCard(params: {
    search?: string;
    MinDate: string;
    MaxDate: string;
    ESId?: string;
    PCId?: string;
    TaxPayerCategory?: string;
    TOId?: string;
    MDAId?: string;
    PGId?: string;
  }) {
    return this.appService.get(
      APIConstant.adminDashboard.getPercentagePaidUnpaidTimeSeries,
      params
    );
  }

  getTotalGeneratedRevenueCard(params: { yr: number }) {
    return this.appService.get(
      `${APIConstant.adminDashboard.getTotalGeneratedRevenueTimeSeries}`,
      params
    );
  }

  getTaxOfficeTopPerformers(params: { MinDate: string; MaxDate: string }) {
    return lastValueFrom(
      this.appService.get(
        `${APIConstant.adminDashboard.getTaxOfficeTopPerformers}`,
        params
      )
    );
  }
  getTaxOfficeBottomPerformers(params: { MinDate: string; MaxDate: string }) {
    return lastValueFrom(
      this.appService.get(
        `${APIConstant.adminDashboard.getTaxOfficeBottomPerformers}`,
        params
      )
    );
  }
  getTaxOfficerPerformances(params: { MinDate: string; MaxDate: string }) {
    return Promise.all([
      this.getTaxOfficeTopPerformers(params),
      this.getTaxOfficeBottomPerformers(params),
    ]);
  }

  getPercentageInvoicePaidByMonthCard(params: {
    search?: string;
    MinDate: string;
    MaxDate: string;
    ESId?: string;
    PCId?: string;
    TaxPayerCategory?: string;
    TOId?: string;
    MDAId?: string;
    PGId?: string;
  }) {
    return this.appService.get(
      `${APIConstant.adminDashboard.getPercentageInvoicePaidByMonth}`,
      params
    );
  }

  getPercentageRevenueCollectedByTaxPayerCategoryCard(params: {
    search?: string;
    MinDate: string;
    MaxDate: string;
    ESId?: string;
    PCId?: string;
    TaxPayerCategory?: string;
    TOId?: string;
    MDAId?: string;
    PGId?: string;
  }) {
    return this.appService.get(
      `${APIConstant.adminDashboard.getPercentageRevenueCollectedByTaxPayerCategory}`,
      params
    );
  }

  getTotalInvoicePaidByBankCard(params: {
    search?: string;
    MinDate: string;
    MaxDate: string;
    ESId?: string;
    PCId?: string;
    TaxPayerCategory?: string;
    TOId?: string;
    MDAId?: string;
    PGId?: string;
  }) {
    return this.appService.get(
      `${APIConstant.adminDashboard.getTotalInvoicePaidByBank}`,
      params
    );
  }

  getPercentageInvoicePaidByTop5MvaCard(params: {
    search?: string;
    MinDate: string;
    MaxDate: string;
    ESId?: string;
    PCId?: string;
    TaxPayerCategory?: string;
    TOId?: string;
    MDAId?: string;
    PGId?: string;
  }) {
    return this.appService.get(
      `${APIConstant.adminDashboard.getPercentageInvoicePaidByTop5Mva}`,
      params
    );
  }

  getPercentageInvoicePaidByBottom5MvaCard(params: {
    search?: string;
    MinDate: string;
    MaxDate: string;
    ESId?: string;
    PCId?: string;
    TaxPayerCategory?: string;
    TOId?: string;
    MDAId?: string;
    PGId?: string;
  }) {
    return this.appService.get(
      `${APIConstant.adminDashboard.getPercentageInvoicePaidByBottom5Mva}`,
      params
    );
  }

  getPercentageInvoicePaidByTop3MdaCard(params: {
    search?: string;
    MinDate: string;
    MaxDate: string;
    ESId?: string;
    PCId?: string;
    TaxPayerCategory?: string;
    TOId?: string;
    MDAId?: string;
    PGId?: string;
  }) {
    return this.appService.get(
      `${APIConstant.adminDashboard.getPercentageInvoicePaidByTop3Mda}`,
      params
    );
  }

  getPercentageInvoicePaidByBottom3MdaCard(params: {
    search?: string;
    MinDate: string;
    MaxDate: string;
    ESId?: string;
    PCId?: string;
    TaxPayerCategory?: string;
    TOId?: string;
    MDAId?: string;
    PGId?: string;
  }) {
    return this.appService.get(
      `${APIConstant.adminDashboard.getPercentageInvoicePaidByBottom3Mda}`,
      params
    );
  }

  getTotalInvoicePaidByGatewayCard(params: {
    search?: string;
    MinDate: string;
    MaxDate: string;
    ESId?: string;
    PCId?: string;
    TaxPayerCategory?: string;
    TOId?: string;
    MDAId?: string;
    PGId?: string;
  }) {
    return this.appService.get(
      `${APIConstant.adminDashboard.getTotalInvoicePaidByGatewayTimeSeries}`,
      params
    );
  }
}
